import { Typography } from 'antd';

const { Text } = Typography;

type PurpleTextProps = {
    className?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
};

export function PurpleText(props: PurpleTextProps) {
    const { children, className, style } = props;

    const classNames = `${className || ''}`.trim();

    return (
        <Text className={classNames} style={style}>
            {children}
        </Text>
    );
}
