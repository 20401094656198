import type { ApiCartao } from 'features/cobranca-cartao/types';
import type { ApiVendaFormaPagamentoPostPix } from 'features/venda-forma-pagamento/types';
import { z } from 'zod';

export enum VendaFormaPagamentoAntecipadoStatus {
    Desconhecido = 0,
    Aguardando = 1,
    Pago = 2,
    Estornado = 3,
}

// TODO
export const vendaFormaPagamentoAntecipadoSchema = z.object({
    status: z.nativeEnum(VendaFormaPagamentoAntecipadoStatus),
    cartao: z.any().nullish(),
    pix: z.any().nullish(),
});

export type VendaFormaPagamentoAntecipado = z.infer<typeof vendaFormaPagamentoAntecipadoSchema> & {
    pix?: ApiVendaFormaPagamentoPostPix;
    cartao?: ApiCartao;
};
