import type { ICompartilhar } from 'components/revisar/FormCompartilhar';
import dayjs from 'dayjs';
import type { Cidade } from 'features/cidade-uf-pais/types';
import type { Cliente } from 'features/cliente/types/Cliente';
import type { ApiContrato } from 'features/contrato/types/ApiContrato';
import type { ApiTransportador } from 'features/transportador/types';
import type { UsuarioEmpresa } from 'features/usuario/types/UsuarioEmpresa';
import type { FormVendaItem } from 'features/venda/page/edicao/types';
import type { Key } from 'react';
import { DATE_FORMAT } from 'std/const/date';
import { createProxyPagination } from 'std/pagination';
import { create } from 'zustand';
import { VendaGetParamsDataTipo } from './api/apiVendaGet';
import type { Venda, VendaTipo } from './types/Venda';

export type VendaFilterTipo = VendaTipo | 'cancelado' | 'total';

export type VendaFilter = {
    tipo: VendaFilterTipo;
    pesquisar: string;
    data_tipo: VendaGetParamsDataTipo;
    data_inicio: string;
    data_fim: string;
    cliente: Cliente;
    cidade: Cidade;
    transportador: ApiTransportador;
    vendedor: UsuarioEmpresa;
    marcadores: string;
    contrato: ApiContrato;
};

export const $storeVendaPagination = createProxyPagination<Venda, VendaFilter>({
    name: 'venda-pagination',
    filter: {
        data_tipo: VendaGetParamsDataTipo.DataGeracao,
        data_inicio: dayjs().subtract(1, 'month').format(DATE_FORMAT),
        data_fim: dayjs().format(DATE_FORMAT),
    },
    apiPagination: {
        orderby: 'ven_data desc, ven_hora desc',
    },
});

type VendaStore = {
    loadingMutation: boolean;

    selectedRow: Venda | null;
    selectedRows: Venda[];
    selectedRowKeys: Key[];

    showDrawerDetalhes: boolean;

    showDrawerCompartilhar: boolean;
    dadosCompartilhar: ICompartilhar | null;

    showDrawerProdutos: boolean;
    drawerProdutoItem: Partial<FormVendaItem> | null;
    drawerProdutoIndex: number | null;
};

export const useVendaStore = create<VendaStore>()(() => ({
    loadingMutation: false,

    selectedRow: null,
    selectedRows: [],
    selectedRowKeys: [],

    showDrawerDetalhes: false,

    showDrawerCompartilhar: false,
    dadosCompartilhar: null,

    showDrawerProdutos: false,
    drawerProdutoItem: null,
    drawerProdutoIndex: null,
}));
