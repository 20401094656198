import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';
import type { VendaTipo } from 'features/venda/types/Venda';
import { type FormEditMode, formEditMode } from 'std/types/enum';
import { z } from 'zod';

const schema = z.object({
    venda_externa: z.boolean().optional(),
});

export const Route = createFileRoute('/a/venda/$tipo/$mode/$idpk/')({
    validateSearch: zodValidator(schema),
    beforeLoad({ params }) {
        const { tipo, mode } = params;

        if (!(tipo.includes(tipo as VendaTipo) && formEditMode.includes(mode as FormEditMode))) {
            throw redirect({ to: '/' });
        }
    },
});
