import { notification } from 'antd';
import { useLoginStore } from 'features/login/store';
import type { RootDispatch, RootState } from 'state/store';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { type BuildUrlParams, buildUrl } from 'std/api2/buildUrl';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import { Endpoint } from 'std/types/enum/endpoint';
import { GetTypes, type GetTypesProps } from 'std/types/interfaces/GetTypes';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            type: GetTypesProps;
            customUrl?: string;
            tra_idpk?: number | string;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.transportadores.setState({
            get: startReduxFn(state.transportadores.get.data),
        });

        try {
            const { transportadores } = state;
            const { pagination, sortParams, filterPesquisar } = transportadores;
            const { type, customUrl, tra_idpk } = payload;

            if (
                type === GetTypes.recordFiltering ||
                type === GetTypes.addRecord ||
                type === GetTypes.singleRecord
            ) {
                dispatch.transportadores.setState({
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: 0,
                    },
                });
            }

            const params: BuildUrlParams = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                total_registros: type === GetTypes.paginationChange ? 'N' : 'S',
                registro_inicial: ((pagination.current ?? 0) - 1) * (pagination.pageSize || 0),
                qtde_registros: pagination?.pageSize || 10,
                pesquisar: filterPesquisar,
                ...(sortParams.shouldSort
                    ? { orderby: `${sortParams.fieldName} ${sortParams.orderDirection}` }
                    : { orderby: 'pes_nome_razaosocial' }),
            };

            let url: string;
            if (type === GetTypes.customUrl && customUrl) {
                url = customUrl;
            } else if (type === GetTypes.singleRecord && tra_idpk) {
                url = buildUrl(Endpoint.Transportador, params, tra_idpk);
            } else {
                url = buildUrl(Endpoint.Transportador, params);
            }

            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            // paginação e filtros devem ser zerados após um get que alteraria a qtde de paginas
            if (type === GetTypes.firstGet || type === GetTypes.addRecord) {
                dispatch.transportadores.setState({
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: response.data.total_registros
                            ? Number(response.data.total_registros)
                            : 0,
                        showTotal: () => `Total de Registros: ${response.data.total_registros}`,
                        showSizeChanger: Number(response.data.total_registros) > 10,
                    },
                    filterPesquisar: undefined,
                    shouldSearch: false,
                });
            }

            // a paginação que já existe no state deve ser mantida, mas o total/showTotal deve mudar
            if (type === GetTypes.recordFiltering || type === GetTypes.deleteRecord) {
                dispatch.transportadores.setState({
                    pagination: {
                        ...pagination,
                        total: response.data.total_registros
                            ? Number(response.data.total_registros)
                            : 0,
                        showTotal: () => `Total de Registros: ${response.data.total_registros}`,
                        showSizeChanger: Number(response.data.total_registros) > 10,
                    },
                });
            }

            dispatch.transportadores.setState({
                get: endReduxFnOk(response.data.registros || []),
            });
        } catch (error) {
            dispatch.transportadores.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async post(
        payload: {
            body: Record<string, any>;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.transportadores.setState({
            post: startReduxFn(state.transportadores.post.data),
        });

        try {
            const { body } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.Transportador, params);
            const response: ApiResponse = await comTokenPost(url, body);
            throwIfResponseIsErr(response);

            const tra_idpk = response.data.registros?.[0]
                ? response.data.registros[0].tra_idpk
                : null;

            dispatch.transportadores.setState({
                post: endReduxFnOk(tra_idpk),
                showModal: false,
            });

            dispatch.transportadores.get({
                type: GetTypes.addRecord,
            });

            notification.success({
                message: 'Feito!',
                description: 'Transportador cadastrada',
            });
        } catch (error) {
            dispatch.transportadores.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível cadastrar a transportador',
                description: error.message,
            });
        }
    },

    async put(
        payload: {
            tra_idpk: number;
            body: Record<string, any>;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.transportadores.setState({
            put: startReduxFn(state.transportadores.put.data),
        });

        try {
            const { tra_idpk, body } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.Transportador, params, tra_idpk);
            const response: ApiResponse = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            dispatch.transportadores.setState({
                put: endReduxFnOk(null),
                showModal: false,
            });

            dispatch.transportadores.get({
                type: GetTypes.editRecord,
            });

            notification.success({
                message: 'Feito!',
                description: 'Transportador atualizada',
            });
        } catch (error) {
            dispatch.transportadores.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível atualizar a transportador',
                description: error.message,
            });
        }
    },

    async remove(payload: { tra_idpk: number }, state: RootState): Promise<void> {
        dispatch.transportadores.setState({
            remove: startReduxFn(state.transportadores.remove.data),
        });

        try {
            const { tra_idpk } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.Transportador, params, tra_idpk);
            const response: ApiResponse = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.transportadores.get({
                type: GetTypes.deleteRecord,
            });

            notification.success({
                message: 'Feito!',
                description: 'Transportador removida',
            });
        } catch (error) {
            dispatch.transportadores.setState({
                remove: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: error.message,
            });
        }
    },
});
