/** @deprecated */
export enum Endpoint {
    EmpresaRamoAtividade = 'EmpresaRamoAtividade/EmpresaRamoAtividade',

    // COM TOKEN

    ClienteRamoAtividadeConsultar = 'ClienteRamoAtividade/Consultar',

    Fornecedor = 'Fornecedor/Fornecedor',

    Transportador = 'Transportador/Transportador',

    ImportacaoClientes = 'Importacao/Clientes',
    ImportacaoProdutos = 'Importacao/Produtos',
    ImportacaoNFERecebida = 'Importacao/NFERecebida',
    ImportacaoNFEEmitida = 'Importacao/NFEEmitida',

    FinanceiroMovimentoAnexo = 'FinanceiroMovimentoAnexo/Anexo',
    FinanceiroMovimentoCliente = 'FinanceiroMovimento/Cliente',
    FinanceiroMovimentoLog = 'FinanceiroMovimentoLog/Log',
    FinanceiroMovimentoLancamento = 'FinanceiroMovimentoLancamento/Lancamento',
    FinanceiroMovimentoLancamentoEstornar = 'FinanceiroMovimentoLancamento/Estornar',
    FinanceiroMovimentoContaReceber = 'FinanceiroMovimento/ContaReceber',
    FinanceiroMovimentoCompartilhar = 'FinanceiroMovimento/Compartilhar',
    FinanceiroMovimentoEstornar = 'FinanceiroMovimento/Estornar',
    FinanceiroMovimentoCancelar = 'FinanceiroMovimento/Cancelar',
    FinanceiroMovimentoContaPagar = 'FinanceiroMovimento/ContaPagar',
    FinanceiroMovimentoValoresTotais = 'FinanceiroMovimento/ValoresTotais',
    FinanceiroMovimentoBoleto = 'FinanceiroMovimento/Boleto',
    FinanceiroMovimentoBoletoValoresTotais = 'FinanceiroMovimento/BoletoValoresTotais',
    FinanceiroMovimentoBoletoGerarListaPDFs = 'FinanceiroMovimento/BoletoGerarPDF',
    FinanceiroMovimentoPixGerarListaPDFs = 'FinanceiroMovimento/PixGerarPDF',
    FinanceiroMovimentoPixInstantaneo = 'FinanceiroMovimento/PixInstantaneo',
    FinanceiroMovimentoPixCobranca = 'FinanceiroMovimento/PixCobranca',
    FinanceiroMovimentoPixValoresTotais = 'FinanceiroMovimento/PixValoresTotais',
    FinanceiroMovimentoPix = 'FinanceiroMovimento/Pix',
    FinanceiroMovimentoEstornarPix = 'FinanceiroMovimento/EstornarPix',
    FinanceiroMovimentoPixPagamentoGerarPDF = 'FinanceiroMovimento/PixPagamentoGerarPDF',
    FinanceiroMovimentoPixCobrancaGerarPDF = 'FinanceiroMovimento/PixCobrancaGerarPDF',
    FinanceiroMovimentoBoletoConsultarSituacao = 'FinanceiroMovimento/BoletoConsultarSituacao',
    FinanceiroMovimentoPixConsultarSituacao = 'FinanceiroMovimento/PixConsultarSituacao',
    BoletoCompartilhar = 'Boleto/Compartilhar',
    PixCompartilhar = 'Pix/Compartilhar',

    FinanceiroContaRetornoBuscar = 'FinanceiroContaRetorno/Buscar',
    FinanceiroContaRetornoProcessarRetornoBancario = 'FinanceiroContaRetorno/ProcessarRetornoBancario',
    FinanceiroContaLancamentoConsultar = 'FinanceiroContaLancamento/Consultar',
    FinanceiroContaLancamentoValoresTotais = 'FinanceiroContaLancamento/ValoresTotais',
    FinanceiroContaLancamentoRemover = 'FinanceiroContaLancamento/Remover',
    FinanceiroContaLancamentoEstornar = 'FinanceiroContaLancamento/Estornar',
    FinanceiroContaLancamentoInserir = 'FinanceiroContaLancamento/Inserir',
    FinanceiroContaLancamentoAlterar = 'FinanceiroContaLancamento/Alterar',

    NFCeConsultar = 'NFCe/Consultar',
    NFCeAlterar = 'NFCe/Alterar',
    NFCeInserir = 'NFCe/Inserir',
    NFCeRemover = 'NFCe/Remover',
    NFCeValoresTotais = 'nfce/ValoresTotais',
    NFCeEnviar = 'NFCe/Enviar',
    NFCeCancelar = 'NFCe/Cancelar',
    NFCeCompartilhar = 'NFCe/Compartilhar',
    NFCeFinanceiro = 'NFCe/Financeiro',
    NFCeEstoque = 'NFCe/Estoque',

    VendaExternaValoresTotais = 'VendaExterna/ValoresTotais',
    VendaExternaGet = 'VendaExterna/Consultar',
    VendaExternaPost = 'VendaExterna/Inserir',
    VendaExternaPut = 'VendaExterna/Alterar',
    VendaExternaRemove = 'VendaExterna/Remover',
    VendaExternaGerarNotaFiscalRetorno = 'VendaExterna/GerarNotaFiscalRetorno',
    VendaExternaAtualizarStatus = 'VendaExterna/AtualizarStatus',
    SequenciaFiscalDispositivoParear = 'SequenciaFiscal/DispositivoParear',

    RegraFiscal = '/RegraFiscal/RegraFiscal',
    RegraFiscalTemplate = '/RegraFiscal/Template',

    RomaneioGet = '/Romaneio/Buscar',
    RomaneioValoresTotais = '/Romaneio/ValoresTotais',
    RomaneioPost = '/Romaneio/Inserir',
    RomaneioPut = '/Romaneio/Alterar',
    RomaneioDelete = '/Romaneio/Remover',

    NotaFiscalOcorrencia = 'NotaFiscalOcorrencia/NotaFiscalOcorrencia',
    NotaFiscalConsultar = 'NotaFiscal/Consultar',
    NotaFiscalEnviar = 'NotaFiscal/Enviar',
    NotaFiscalCartaCorrecao = 'NotaFiscal/CartaCorrecao',
    NotaFiscalAtor = 'NotaFiscal/Ator',
    NotaFiscalCancelar = 'NotaFiscal/Cancelar',
    NotaFiscal = 'NotaFiscal/NotaFiscal',
    NotaFiscalTotais = 'NotaFiscal/ValoresTotais',
    NotaFiscalPreVisualizar = 'NotaFiscal/PreVisualizar',
    NotaFiscalGerarDanfes = 'NotaFiscal/GerarDANFES',
    NotaFiscalLog = 'NotaFiscal/Log',
    NotaFiscalCalcularImpostos = 'NotaFiscal/CalcularImpostos',
    NotaFiscalFinanceiro = 'NotaFiscal/Financeiro',
    NotaFiscalEstoque = 'NotaFiscal/Estoque',
    NotaFiscalCompartilhar = 'NotaFiscal/Compartilhar',
    NotaFiscalLinkCompartilhamento = 'NotaFiscal/LinkCompartilhamento',
    NotaFiscalGerarEstorno = 'NotaFiscal/GerarEstorno',
    NotaFiscalEnviarExpedicao = 'NotaFiscal/EnviarExpedicao',
    NotaFiscalStatusServico = 'NotaFiscal/StatusServico',

    Compra = 'Compra/Compra',
    CompraLancar = 'Compra/Lancar',
    CompraResumoLancamento = 'Compra/ResumoLancamento',
    CompraEstornar = 'Compra/EstornarLancamento',
    CompraCarregarManifestada = 'Compra/ImportarNotaManifestada',

    ManifestadorManifestar = 'ManifestadorDfe/Manifestar',
    ManifestadorBuscarNaReceita = 'ManifestadorDfe/BuscarNaReceita',

    FiscalInutilizar = 'Fiscal/Inutilizar',
    Fiscal = 'Fiscal/Inutilizacao',

    Relatorio = 'Relatorio/Relatorio',
    RelatorioCadastroRelacaoCliente = 'Relatorio/CadastroRelacaoCliente',
    RelatorioComissaoMovimento = 'Relatorio/ComissaoMovimento',
    RelatorioEstoqueSaldo = 'Relatorio/EstoqueSaldo',
    RelatorioEstoqueMinimo = 'Relatorio/EstoqueMinimo',
    RelatorioFinanceiroContaBalancete = 'Relatorio/FinanceiroContaBalancete',
    RelatorioFinanceiroContaExtrato = 'Relatorio/FinanceiroContaExtrato',
    RelatorioFinanceiroContaCategorias = 'Relatorio/FinanceiroContaCategorias',
    RelatorioFinanceiroValoresRecebidosResumo = 'Relatorio/FinanceiroValoresRecebidosResumo',
    RelatorioFinanceiroMovimentoFluxoCaixa = 'Relatorio/FinanceiroMovimentoFluxoCaixa',
    RelatorioFinanceiroMovimentoContasReceber = 'Relatorio/FinanceiroMovimentoContasReceber',
    RelatorioFinanceiroMovimentoContasPagar = 'Relatorio/FinanceiroMovimentoContasPagar',
    RelatorioFinanceiroMovimentoLancamentosReceber = 'Relatorio/FinanceiroMovimentoLancamentosReceber',
    RelatorioFinanceiroMovimentoLancamentosPagar = 'Relatorio/FinanceiroMovimentoLancamentosPagar',
    RelatorioNotaFiscalCliente = 'Relatorio/NotaFiscalCliente',
    RelatorioNotaFiscalProduto = 'Relatorio/NotaFiscalProduto',
    RelatorioNotaFiscalTipo = 'Relatorio/NotaFiscalTipo',
    RelatorioNotaFiscalItens = 'Relatorio/NotaFiscalItens',
    RelatorioVendaRelacao = 'Relatorio/VendaRelacao',
    RelatorioVendaRelacaoResumido = 'Relatorio/VendaRelacaoResumido',
    RelatorioVendaRelacaoClienteVendedor = 'Relatorio/VendaRelacaoClienteVendedor',
    RelatorioCompraFornecedor = 'Relatorio/CompraFornecedor',

    NotaFiscalRegraPreenchimentoConsultar = 'NotaFiscalRegraPreenchimento/Consultar',
    NotaFiscalRegraPreenchimentoInserir = 'NotaFiscalRegraPreenchimento/Inserir',
    NotaFiscalRegraPreenchimentoAlterar = 'NotaFiscalRegraPreenchimento/Alterar',
    NotaFiscalRegraPreenchimentoRemover = 'NotaFiscalRegraPreenchimento/Remover',

    OfxConsultar = 'Ofx/Consultar',
    OfxCarregarArquivo = 'Ofx/CarregarArquivo',
    OfxRemover = 'Ofx/Remover',

    CondicaoPagamentoConsultar = 'CondicaoPagamento/Consultar',
    CondicaoPagamentoInserir = 'CondicaoPagamento/Inserir',
    CondicaoPagamentoAlterar = 'CondicaoPagamento/Alterar',
    CondicaoPagamentoRemover = 'CondicaoPagamento/Remover',

    MDFeConsultar = 'MDFe/Consultar',
    MDFeValoresTotais = 'MDFe/ValoresTotais',
    MDFeInserir = 'MDFe/Inserir',
    MDFeAlterar = 'MDFe/Alterar',
    MDFeRemover = 'MDFe/Remover',
    MDFeEnviar = 'MDFe/Enviar',
    MDFeEncerrar = 'MDFe/Encerrar',
    MDFeCancelar = 'MDFe/Cancelar',
    MDFePreVisualizar = 'MDFe/PreVisualizar',

    AtendimentoConsultar = 'Atendimento/Consultar',
    AtendimentoInserir = 'Atendimento/Inserir',
    AtendimentoAlterar = 'Atendimento/Alterar',
    AtendimentoRemover = 'Atendimento/Remover',

    ComissaoMovimentoConsultar = 'ComissaoMovimento/Consultar',
    ComissaoMovimentoValoresTotais = 'ComissaoMovimento/ValoresTotais',
    ComissaoMovimentoInserir = 'ComissaoMovimento/Inserir',
    ComissaoMovimentoAlterar = 'ComissaoMovimento/Alterar',
    ComissaoMovimentoRemover = 'ComissaoMovimento/Remover',

    DReGerar = 'DRe/Gerar',
}
