import { apiInternoErro } from 'features/erro/api/apiInternoErroPost';
import { type AnyZodObject, z } from 'zod';

export function zodParse<Schema extends z.AnyZodObject>(
    schema: Schema,
    data: unknown,
): z.infer<Schema> {
    const result = schema.safeParse(data);

    if (result.success) {
        return result.data;
    }

    // biome-ignore lint/suspicious/noConsole: Facilita saber pq o zod falhou
    console.error(result.error);

    apiInternoErro({
        type: 'ZOD_PARSE_ERROR',
        message: JSON.stringify(result.error),
    });

    throw result.error;
}

export function createDefaultSchema<T extends AnyZodObject>(obj: T) {
    return z.object({
        registros: z.array(obj),
        total_registros: z.number().optional(),
        status: z.literal('sucesso'),
    });
}
