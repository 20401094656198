import { AcaoSchema } from 'features/acao/types';
import { SimNaoNumber } from 'std/types';
import type { DeepUndefinedOrNull } from 'std/types/partial';
import { z } from 'zod';
import { VendaFormaPagamentoSchema } from '../../venda-forma-pagamento/types/VendaFormaPagamento';
import { VendaItemSchema } from '../../venda-item/types/VendaItem';

export const VENDA_TIPO = ['pedido', 'orçamento', 'venda', 'bonificação'] as const;
export type VendaTipo = (typeof VENDA_TIPO)[number];

export const VENDA_STATUS = [
    'Finalizada',
    'Cancelada',
    'Pendente',
    'Recusado',
    'Cancelado',
    'Prevista',
] as const;
export type VendaStatus = (typeof VENDA_STATUS)[number];

export enum VendaFreteResponsavel {
    ContratacaoFreteRemetenteCIF = 0,
    ContratacaoFreteDestinatarioFOB = 1,
    ContratacaoFreteTerceiros = 2,
    TransporteProprioRemetente = 3,
    TransporteProprioDestinatario = 4,
    SemOcorrenciaTransporte = 9,
}

export enum VendaOrigem {
    Venda = 1,
    NFe = 2,
    NFCe = 3,
    Compra = 4,
    ContaReceber = 5,
    ContaPagar = 6,
    Boleto = 7,
    Pix = 8,
}

export const VendaNotaFiscalSchema = z.object({
    vnf_idpk: z.number().nullable(),
    vnf_venda_idpk: z.number().nullable(),
    vnf_nfce_idpk: z.number().nullable(),
    vnf_nota_fiscal_idpk: z.number().nullable(),
});

export type VendaNotaFiscal = z.infer<typeof VendaNotaFiscalSchema>;

export const VendaSistemaIntegradoSchema = z.object({
    ven_dispositivo_idpk: z.number().nullable(),
    ven_sistema_integrado_referencia: z.string().nullable(),
    sii_idpk: z.number().nullable(),
    sii_nome: z.string().nullable(),
});

export type VendaSistemaIntegrado = z.infer<typeof VendaSistemaIntegradoSchema>;

export const VendaSchema = z.object({
    acoes: z.array(AcaoSchema).nullable(),
    formas_de_pagamento: z.array(VendaFormaPagamentoSchema).nullable(),
    itens: z.array(VendaItemSchema).nullable(),
    sistema_integrado: VendaSistemaIntegradoSchema.nullable(),
    venda_nota_fiscal: z.array(VendaNotaFiscalSchema).nullable(),

    ven_idpk: z.number(),
    ven_empresa_idpk: z.number(),
    ven_tipo: z.enum(VENDA_TIPO),
    ven_status: z.enum(VENDA_STATUS),
    ven_data: z.string(),
    ven_valor_total: z.number(),
    ven_peso_bruto: z.number(),
    ven_peso_liquido: z.number(),

    ven_numero: z.string().nullable(),
    ven_financeiro_categoria_idpk: z.number().nullable(),
    ven_tabela_preco_idpk: z.number().nullable(),
    ven_hora: z.string().nullable(),
    ven_venda_externa_idpk: z.number().nullable(),
    ven_contrato_idpk: z.number().nullable(),
    ven_marcadores: z.string().nullable(),
    ven_ultima_alteracao: z.string().nullable(),
    ven_volume_qtde: z.number().nullable(),
    ven_frete_responsavel: z.nativeEnum(VendaFreteResponsavel).nullable(),
    ven_validade_ate: z.string().nullable(),
    ven_link_compartilhamento: z.string().nullable(),
    ven_nota_gerada: z.enum(['S', 'N']).nullable(),
    ven_romaneio: z.nativeEnum(SimNaoNumber).nullable(),
    ven_cliente_idpk: z.number().nullable(),
    ven_cliente_nome: z.string().nullable(),
    ven_cliente_apelido: z.string().nullable(),
    ven_cliente_documento: z.string().nullable(),
    ven_cliente_cep: z.string().nullable(),
    ven_cliente_logradouro: z.string().nullable(),
    ven_cliente_logradouro_numero: z.string().nullable(),
    ven_cliente_complemento: z.string().nullable(),
    ven_cliente_bairro: z.string().nullable(),
    ven_cliente_cidade_idpk: z.number().nullable(),
    ven_cliente_cidade: z.string().nullable(),
    ven_cliente_uf: z.string().nullable(),
    ven_cliente_pais_codigo: z.number().nullable(),
    ven_cliente_responsavel: z.string().nullable(),
    ven_cliente_fone: z.string().nullable(),
    ven_cliente_email: z.string().nullable(),
    ven_vendedor_idpk: z.number().nullable(),
    ven_vendedor_nome: z.string().nullable(),
    ven_vendedor_sequencial: z.number().nullable(),
    ven_entregador_idpk: z.number().nullable(),
    ven_produtos_valor: z.number().nullable(),
    ven_desconto_valor: z.number().nullable(),
    ven_desconto_tipo: z.enum(['%', '$', '']).nullable(),
    ven_transportador_idpk: z.number().nullable(),
    ven_transportador_nome: z.string().nullable(),
    ven_transportador_documento: z.string().nullable(),
    ven_observacao_interna: z.string().nullable(),
    ven_observacao_cliente: z.string().nullable(),
    ven_origem: z.nativeEnum(VendaOrigem).nullable(),
    ven_origem_id: z.number().nullable(),
    ven_previsao_faturamento: z.string().nullable(),
    ven_previsao_entrega_de: z.string().nullable(),
    ven_previsao_entrega_ate: z.string().nullable(),
    ven_usuario_idpk: z.number().nullable(),
    ven_usuario: z.string().nullable(),
    ven_data_orcamento: z.string().nullable(),
    ven_data_pedido: z.string().nullable(),
    ven_data_venda: z.string().nullable(),
    ven_total_seguro: z.number().nullable(),
    ven_total_despesas_acessorias: z.number().nullable(),
    ven_total_frete: z.number().nullable(),
    ven_total_imp_icmsst_valor: z.number().nullable(),
    ven_total_imp_fcpst_valor: z.number().nullable(),
    ven_total_imp_ipi_valor: z.number().nullable(),
    ven_total_imp_ii_valor: z.number().nullable(),
});

export type Venda = z.infer<typeof VendaSchema>;
export type VendaBody = DeepUndefinedOrNull<Venda>;
