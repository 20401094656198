import type { RoutePath } from 'router/util';
import { devtools, persist } from 'zustand/middleware';
import { createWithEqualityFn as create } from 'zustand/traditional';

type LayoutStore = {
    breadcrumb: {
        path: string[];
        voltarTo?: RoutePath;
    };

    isFullscreen: boolean;

    menuOpen: boolean;
    menuMobile: boolean;
    menuOpenKeys: string[];
    menuSelectedKey: string;

    newVersion?: string;

    toggleFullscreen: () => void;
};

export const useLayoutStore = create<LayoutStore>()(
    devtools(
        persist(
            (_, get) => ({
                breadcrumb: {
                    path: [],
                },

                isFullscreen: false,

                menuOpen: true,
                menuMobile: false,
                menuOpenKeys: [],
                menuSelectedKey: 'painel-gerencial',

                async toggleFullscreen() {
                    // Safari doesn't support requestFullscreen
                    // https://developer.mozilla.org/en-US/docs/Web/API/Element/requestFullscreen#browser_compatibility
                    if (!document.documentElement.requestFullscreen) {
                        return;
                    }

                    const { isFullscreen } = get();

                    if (isFullscreen) {
                        await document.exitFullscreen();
                    } else {
                        await document.documentElement.requestFullscreen();
                    }
                },
            }),
            {
                name: 'layout',
                partialize: (state) => ({
                    menuOpenKeys: state.menuOpenKeys,
                    menuSelectedKey: state.menuSelectedKey,
                }),
            },
        ),
        { name: 'layout' },
    ),
);
