import { HomeOutlined } from '@ant-design/icons';
import { Link } from '@tanstack/react-router';
import { Breadcrumb, Grid } from 'antd';
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { ButtonVoltar } from 'components/button/ButtonVoltar';
import { useLayoutStore } from 'features/layout/store';
import { useMemo } from 'react';

export function Breadcrumbs() {
    const screens = Grid.useBreakpoint();
    const breadcrumbs = useLayoutStore((s) => s.breadcrumb.path);
    const voltarTo = useLayoutStore((s) => s.breadcrumb.voltarTo);

    const items = useMemo(() => {
        const innerItems: ItemType[] = [];

        const home = {
            title: (
                <Link to='/a/painel-gerencial'>
                    <HomeOutlined style={{ paddingRight: 5 }} />
                    Início
                </Link>
            ),
        };

        innerItems.push(home);

        breadcrumbs?.map((title) => {
            innerItems.push({
                title,
            });
        });

        return innerItems;
    }, [breadcrumbs]);

    return (
        <div
            style={{
                padding: screens.md ? '0.5rem 1rem 0' : '0.5rem 0.6rem 0',
            }}
            className='flex items-center gap-2'
        >
            {voltarTo && <ButtonVoltar to={voltarTo} />}
            <Breadcrumb items={items} separator='>' />
        </div>
    );
}
