import { router } from 'router/Router';

export type GlobalSearchMapItem = {
    pattern: RegExp[];
    description: (matchArray: RegExpMatchArray) => string;
    action: (matchArray: RegExpMatchArray) => void;
};

export const globalSearchMap: GlobalSearchMapItem[] = [
    {
        pattern: [/^cli (.+)$/, /^cliente (.+)$/],
        description: (matchArray: RegExpMatchArray) => `Pesquisar cliente: ${matchArray[1]}`,
        action: (matchArray: RegExpMatchArray) => {
            router.navigate({ to: '/a/cliente', search: { pesquisar: matchArray[1] } });
        },
    },
];
