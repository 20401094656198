import type { TablePaginationConfig } from 'antd';
import type {
    ApiPagination,
    ProxyPagination,
    ProxyPaginationState,
    TableChangeSorter,
} from 'std/pagination/types';
import { isObjectEmpty } from 'std/util';
import { proxy, subscribe } from 'valtio/vanilla';

export const DEFAULT_API_PAGINATION: ApiPagination = {
    registro_inicial: 0,
    qtde_registros: 10,
    total_registros: 'S',
};

export const DEFAULT_TABLE_PAGINATION: TablePaginationConfig = {
    current: 1,
    pageSize: 10,
    showTotal,
};

export function showTotal(total: number): string {
    return `Total de registros: ${total}`;
}

export function getOrderBy<Obj extends Record<string, unknown>>(
    sorter: TableChangeSorter<Obj>,
): string | undefined {
    if (Array.isArray(sorter) || isObjectEmpty(sorter as Record<string, unknown>)) {
        return undefined;
    }

    return `${sorter.field} ${sorter.order === 'descend' ? 'desc' : 'asc'}`;
}

function restoreStateFromSessionStorage<Filter extends Record<string, unknown>>(
    name: string,
    defaultState: ProxyPaginationState<Filter>,
): ProxyPaginationState<Filter> {
    try {
        const session = sessionStorage.getItem(name);
        return session ? JSON.parse(session) : defaultState;
    } catch (error) {
        // biome-ignore lint/suspicious/noConsole: <explanation>
        console.error(`Failed to restore "${name}" from sessionStorage:`, error);
        return defaultState;
    }
}

export function createProxyPagination<
    Obj extends Record<string, unknown>,
    Filter extends Record<string, unknown>,
>({
    name,
    filter,
    apiPagination,
}: {
    name?: string;
    filter?: Partial<Filter>;
    apiPagination?: ApiPagination;
}) {
    const defaultState: ProxyPaginationState<Filter> = {
        filter: filter && !isObjectEmpty(filter) ? filter : null,
        apiPagination: {
            ...DEFAULT_API_PAGINATION,
            ...apiPagination,
        },
        tablePagination: DEFAULT_TABLE_PAGINATION,
    };

    const initialState = name ? restoreStateFromSessionStorage(name, defaultState) : defaultState;

    const $store = proxy<ProxyPagination<Obj, Filter>>({
        ...initialState,
        setFilter: (newFilter) => {
            const filter = newFilter ? { ...$store.filter, ...newFilter } : null;
            $store.filter = filter && !isObjectEmpty(filter) ? filter : null;

            $store.apiPagination = {
                ...$store.apiPagination,
                total_registros: 'S',
                registro_inicial: 0,
            };

            $store.tablePagination = {
                ...$store.tablePagination,
                current: 1,
            };
        },
        onTableChange: (pagination, _, sorter) => {
            const page = pagination.current ?? 0;
            const pageSize = pagination.pageSize ?? 10;
            const orderby = getOrderBy(sorter);

            $store.apiPagination = {
                ...$store.apiPagination,
                registro_inicial: (page - 1) * pageSize,
                qtde_registros: pageSize,
                total_registros: 'N',
                orderby,
            };

            $store.tablePagination = {
                ...$store.tablePagination,
                ...pagination,
                showTotal,
            };
        },
        setTotalRegistros: (totalRegistros) => {
            $store.apiPagination.total_registros = totalRegistros;
        },
        setTotal: (total) => {
            if (typeof total === 'number') {
                $store.tablePagination.total = total;
                $store.tablePagination.showSizeChanger = total > 10;
            }
        },
    });

    if (name) {
        subscribe($store, () => {
            sessionStorage.setItem(name, JSON.stringify($store));
        });
    }

    return $store;
}
