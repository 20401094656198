import { z } from 'zod';

export enum ErroOrigem {
    Todos = 0,
    Backend = 1,
    Frontend = 2,
    WebLocal = 3,
    Lacto = 4,
    Outros = 99,
}

export const ErroSchema = z.object({
    err_idpk: z.number(),
    err_origem: z.nativeEnum(ErroOrigem),
    err_conteudo: z.string(),
    err_ultima_alteracao: z.string(),
});

export type Erro = z.infer<typeof ErroSchema>;
